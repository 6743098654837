import { styled } from '@/styles';

export const ButtonBased = styled('button', {
  width: '100%',
  height: '3rem',
  display: 'flex',
  marginTop: '1.625rem',
  padding: '0.7rem 2.0rem 0.7rem 2.0rem',
  borderRadius: '20px',
  border: 0,
  background: '$blueWarmVivid70',
  color: '$pure',
  fontSize: '$sm',
  fontWeight: 600,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  variants: {
    variantType: {
      default: {},
      outline: {
        background: 'none',
        color: '$blueWarmVivid70',
        border: '0.0625rem solid $blueWarmVivid70',
      },
    },
  },
});
