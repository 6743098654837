import { createStitches } from '@stitches/react';

export const {
  config,
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
} = createStitches({
  theme: {
    colors: {
      blueWarmVivid90: '#071D41',
      blueWarmVivid70: '#1351B4',
      greenCoolVivid05: '#E3F5E1',
      greenCoolVivid50: '#168821',
      pure: '#FFFFFF',
      gray05: '#F0F0F0',
      gray20: '#CCCCCC',
      redWarning05: '#FFCDD2',
      white: 'white',
      gray90: '#1B1B1B',
      pureBlack: '#000000',
      warning: '#FFA800',
    },
    fontSizes: {
      lg: '2.25rem',
      md: '1.5rem',
      sm: '1rem',
      xs: '0.875rem',
    },
  },
});

export type ThemeColors = typeof config.theme.colors;
export type ThemeSizes = typeof config.theme.fontSizes;
