import { styled } from '@/styles';

export const Container = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100vh',
});

export const LeftDiv = styled('div', {
  background: 'linear-gradient(to right, $blueWarmVivid90, $blueWarmVivid70)',
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  color: '$white',
  padding: '0 2.5rem',

  h1: {
    marginTop: '10.531rem',
    fontSize: '$lg',
    fontWeight: 400,

    strong: {
      fontWeight: 700,
    },
  },

  span: {
    fontSize: '$md',
  },

  p: {
    marginTop: '1rem',
    fontSize: '$sm',
  },

  hr: {
    marginTop: '1rem',
    width: '100%',
    display: 'block',
  },
});

export const RightDiv = styled('div', {
  backgroundColor: '$pure',
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  div: {
    span: {
      color: '$gray90',
    },
    strong: {
      fontWeight: 800,
    },
    button: {
      height: 40,
      span: {
        color: '$pure',
      },
    },
  },

  p: {
    marginTop: '1.625rem',
    fontSize: '$xs',
    color: '$gray90',

    span: {
      fontWeight: 600,
      color: '$blueWarmVivid70',
    },
  },
});
