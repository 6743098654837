'use client';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import Loader from '@/components/shared/loader';
import { Container, LeftDiv, RightDiv } from '@/styles/pages/auth/sign-in';
import { SeparatorLine } from '@/components/UI/SeperatorLine';
import Button from '@/components/UI/Button';

export default function SignIn() {
  const [isLoading, setIsLoading] = useState(false);

  function handleClick() {
    setIsLoading(true);
    signIn('keycloak');
  }

  return (
    <Container>
      <LeftDiv>
        <h1>
          PNLD <strong>Avaliação Pedagógica</strong>
        </h1>
        <span>Etapa de Submissão de Recurso e Correções</span>

        <p>
          Acesso para as editoras para gerenciamento da Etapa de Submissão de
          Recurso e <br />
          Correções do processo de Avaliação Pedagógica do Programa Nacional do
          Livro e do <br />
          Material Didático.
        </p>

        <SeparatorLine />
      </LeftDiv>

      <RightDiv>
        <div>
          <span>
            O representante da editora deve autenticar-se <br />
            via <strong>gov.br</strong> para acessar a plataforma.
          </span>

          <Button onClick={() => handleClick()} disabled={isLoading}>
            {isLoading ? (
              <Loader />
            ) : (
              <span>
                Entrar com <strong>&nbsp;gov.br</strong>
              </span>
            )}
          </Button>

          <p>
            Dúvidas ou dificuldades? <a href='#'>Entre em contato</a>{' '}
          </p>
        </div>
      </RightDiv>
    </Container>
  );
}
